<template>
  <div>
    <div class="bordered pb-4">
      <div class="grid-x grid-padding-x pt-1">
        <div class="cell small-2">
          <router-link to="/">
            <img width="12px" src="../../assets/icons/arrow_left.svg" alt="Back" />
          </router-link>
        </div>
        <div class="cell small-8">
          <h2 class="text-center basket-header">Customisation</h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-2">
        <div class="cell small-12">
          <p class="text-blue text-center">
            You will receive a visual proof for approval prior to production
          </p>
        </div>
      </div>
      <div class="grid-x">
        <div class="cell small-12">
          <div class="bg ma-2 br-5 pa-2">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12 relative">
                <h5 id="typeWarning">Type of customisation</h5>
                <p
                  class="warning mb-0 text-center"
                  v-if="typeWarning.length > 0">
                  {{ typeWarning }}
                </p>
                <span
                  @click="goBack"
                  class="close">
                  <img src="../../assets/icons/red_close.svg" alt="X" />
                </span>
              </div>
            </div>
            <div class="grid-x grid-padding-x">
              <div class="cell small-6">
                <span
                  class="toggle-button print"
                  :class="{ 'active' : type === 1 }"
                  @click="type = 1"
                  >Print</span>
              </div>
              <div class="cell small-6">
                <span
                  class="toggle-button embroidery"
                  @click="type = 2"
                  :class="{ 'active' : type === 2 }"
                  >Embroidery</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x">
        <div class="cell small-12">
          <div class="bg ma-2 br-5 pa-2">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12">
                <h5 id="positionWarning" class="mb-0">Design position</h5>
                <p
                  class="warning mb-0 text-center"
                  v-if="positionWarning">
                  {{ positionWarning }}
                </p>
              </div>
            </div>
            <div class="grid-x grid-padding-x">
              <div class="cell small-12">
                <agile
                  :slides-to-show="2"
                  :nav-buttons="true"
                  :speed="5"
                  :center-mode="true"
                  :dots="false">
                  <div class="slide position-selector-container">
                    <div
                      @click="position = 1"
                      :class="{ 'active' : position === 1 }"
                      class="slide position-selector pos-flc">
                      <div class="position-title">Front</div>
                      <div class="position-sub-title">Left chest</div>
                    </div>
                  </div>
                  <div class="slide position-selector-container">
                    <div
                      @click="position = 2"
                      :class="{ 'active' : position === 2 }"
                      class="slide position-selector pos-frc">
                      <div class="position-title">Front</div>
                      <div class="position-sub-title">Right chest</div>
                    </div>
                  </div>
                  <div class="slide position-selector-container">
                    <div
                      @click="position = 3"
                      :class="{ 'active' : position === 3 }"
                      class="slide position-selector pos-fc">
                      <div class="position-title">Front</div>
                      <div class="position-sub-title">Center</div>
                    </div>
                  </div>
                  <div class="slide position-selector-container">
                    <div
                      @click="position = 4"
                      :class="{ 'active' : position === 4 }"
                      class="slide position-selector pos-ls">
                      <div class="position-title">Sleeve</div>
                      <div class="position-sub-title">Left</div>
                    </div>
                  </div>
                  <div class="slide position-selector-container">
                    <div
                      @click="position = 5"
                      :class="{ 'active' : position === 5 }"
                      class="slide position-selector pos-rs">
                      <div class="position-title">Sleeve</div>
                      <div class="position-sub-title">Right</div>
                    </div>
                  </div>
                  <div class="slide position-selector-container">
                    <div
                      @click="position = 6"
                      :class="{ 'active' : position === 6 }"
                      class="slide position-selector pos-bn">
                      <div class="position-title">Back</div>
                      <div class="position-sub-title">Neck</div>
                    </div>
                  </div>
                  <div class="slide position-selector-container">
                    <div
                      @click="position = 7"
                      :class="{ 'active' : position === 7 }"
                      class="slide position-selector pos-bc">
                      <div class="position-title">Back</div>
                      <div class="position-sub-title">Center</div>
                    </div>
                  </div>
                </agile>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x">
        <div class="cell small-12">
          <div class="bg ma-2 br-5 pa-2">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12">
                <h5 id="artworkWarning" class="mb-1">Artwork</h5>
                <p
                  v-if="artworkWarning.length > 0"
                  class="warning mb-0 text-center">
                  {{ artworkWarning }}
                </p>
              </div>
            </div>
            <div class="grid-x grid-padding-x">
              <div v-if="uploaded" class="cell small-6 text-right">
                <span class="upload-successful">Successful</span>
              </div>
            </div>
            <div v-if="image.length === 0 && textAdded === false">
              <div class="grid-x grid-padding-x pt-1">
                <div class="cell small-12">
                  <Uploader @closeUploader="closeUploader" />
                </div>
              </div>
              <div
                v-if="$store.state.customer.id"
                class="grid-x grid-padding-x" style="margin-top:1px">
                <div class="cell small-12">
                  <button
                    :class="{ active: artworkLoaded }"
                    class="toggle-button small"
                    @click="loadArtwork()">
                    Load Saved Artwork
                  </button>
                </div>
              </div>
              <div
                v-else
                class="grid-x grid-padding-x" style="margin-top:1px">
                <div class="cell small-12">
                  <button
                    class="toggle-button small"
                    @click="signUpBox = true">
                    Load Saved Artwork
                  </button>
                </div>
              </div>
              <div
                class="grid-x grid-padding-x mt-2 pb-2">
                <div class="cell small-12">
                  <button
                    :class="{ active: textAdded }"
                    class="toggle-button small"
                    @click="textEditor = true">
                    Text creator
                  </button>
                </div>
              </div>
            </div>
            <div class="grid-x grid-padding-x">
              <div class="cell small-12">
                <div class="sign-up-box" v-if="signUpBox">
                  <button
                    @click="goToProfile('logIn')"
                    class="button rounded">Sign In</button>
                  &nbsp;
                  <button
                    @click="goToProfile('register')"
                    class="button rounded blue">Register</button>
                  <span @click="signUpBox = false" class="close">X</span>
                </div>
              </div>
            </div>
            <div
              class="artwork-selector-box"
              v-if="$store.state.images.length > 1">
              <div
                class="grid-x grid-padding-x pt-2 pb-2">
                <div class="cell small-12">
                  <h4>Select artwork</h4>
                </div>
                <div
                  v-for="(imageT, index) in images" :key="index"
                  class="cell small-3 medium-2">
                  <div
                    @click="chooseArtwork(index)"
                    class="artwork relative"
                    :class="{ active: chosenArtwork === index }">
                    <span class="indicator" :class="{ print: type === 1 }"></span>
                    <img
                      :src="`https://runsmart-dropship.s3.eu-west-2.amazonaws.com/new_uploads/thumbnail_200/${imageT}`"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="image.length > 0"
              class="grid-x grid-padding-x mt-1 mb-2">
              <div class="cell small-4 small-offset-2 artwork relative active">
                <span class="indicator" :class="{ print: type === 1 }"></span>
                <img
                  :src="`https://runsmart-dropship.s3.eu-west-2.amazonaws.com/new_uploads/thumbnail_200/${image}`"
                  alt="" />
              </div>
              <div class="cell small-5">
                <button
                  @click="clearArtwork"
                  class="button mt-5">Change</button>
              </div>
            </div>
            <div
              class="decorative-text-box relative"
              v-if="textAdded == true">
              <span class="indicator" :class="{ print: type === 1 }"></span>
              <span
                :style="{ fontFamily: textFont }"
                :class="textSize"
                class="decorative-text">
                {{ text }}
              </span>
              <div class="cell small-5">
                <button
                  @click="clearArtwork"
                  class="button mt-5">Change</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div id="sizeWarning" class="cell small-12">
          <p
            class="warning mb-0 text-center"
            v-if="sizeWarning.length > 0">
            {{ sizeWarning }}
          </p>
          <span
            class="button small ghost lc expanded mb-2"
            :class="{ 'active' : size === 1 }"
            @click="size = 1"
            >
              Small
              <span v-if="largeSizes">10cm</span>
              <span v-else>5cm</span>
          </span>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <span
            class="button small ghost lc expanded mb-2"
            @click="size = 2"
            :class="{ 'active' : size === 2 }"
            >
            Medium
            <span v-if="largeSizes">20cm</span>
            <span v-else>8cm</span>
          </span>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <span
            class="button small ghost lc expanded mb-2"
            @click="size = 3"
            :class="{ 'active' : size === 3 }">
            Large
            <span v-if="largeSizes">30cm</span>
            <span v-else>10cm</span>
          </span>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-1">
        <div class="cell small-6">
          <h6>Custom size (cm)</h6>
        </div>
        <div class="cell small-6">
          <button class="number-control-minus inline border" @click="designSizeChange(0)">-</button>
          <input
            class="number-control-input no-bg border inline mb-2"
            v-model="designSize" type="number"
            @click="size = 4"
            min="0"
            step="1" />
          <button class="number-control-plus inline border" @click="designSizeChange(1)">+</button>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <input
            v-model="notes"
            class="ghost-input no-bg mb-2 wand-icon"
            type="text"
            placeholder="Notes/need help removing background? Just let us know" />
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div id="nameWarning" class="cell small-12">
          <p
            class="warning mb-0 text-center"
            v-if="nameWarning">
            {{ nameWarning }}
          </p>
          <input
            v-model="name"
            class="ghost-input no-bg mb-2"
            type="text"
            placeholder="Customisation name:" />
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center">
          <button
            v-if="addedToBasket === false"
            @click="saveChanges"
            class="button rounded mb-5">Confirm</button>
        </div>
      </div>
    </div>
    <div v-if="addedToBasket === true" class="modal-bottom top-layer">
      <router-link
        to="/"
        class="button ghost mb-5">
        Add more garments
        <img width="18px" src="../../assets/icons/add_garments_icon.png" alt="" />
      </router-link>
      <button
        @click="addNewPosition"
        class="button ghost mb-5">
        Add additional positions
        <img width="18px" src="../../assets/icons/add_positions_icon.png" alt="" />
      </button>
      <router-link
        to="/basket"
        class="button rounded mb-5">
        Continue to summary
        <img width="18px" src="../../assets/icons/shopping_cart_transparent.svg" alt="" />
      </router-link>
    </div>
    <div
      v-if="textEditor === true"
      class="modal-uploader">
      <div class="modal-inner">
        <span
          @click="textEditor = false"
          class="modal-close">X</span>
          <div class="grid-x grid-padding-x">
            <div class="col small-6">
              <h3>Text creator</h3>
            </div>
            <div class="col small-3">
              <select class="small" v-model="textFont">
                <option value="0">Font</option>
                <option style="font-family: 'arial'" value="arial">
                  Arial
                </option>
                <option style="font-family: 'verdana'" value="verdana">
                  Verdana
                </option>
                <option style="font-family: 'helvetica'" value="helvetica">
                  Helvetica
                </option>
                <option style="font-family: 'tahoma'" value="tahoma">
                  Tahoma
                </option>
                <option style="font-family: 'trebuchet ms'" value="trebuchet ms">
                  Trebuchet MS
                </option>
                <option style="font-family: 'times new roman'" value="times new roman">
                  Times New Roman
                </option>
                <option style="font-family: 'georgia'" value="georgia">
                  Georgia
                </option>
                <option style="font-family: 'garamond'" value="garamond">
                  Garamond
                </option>
                <option style="font-family: 'courier new'" value="courier new">
                  Courier New
                </option>
                <option style="font-family: 'brush script mt'" value="brush script mt">
                  Brush Script MT
                </option>
              </select>
            </div>
            <div class="col small-3">
              <select class="small" v-model="textSize">
                <option value="0">Size</option>
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </select>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="col small-12">
              <textarea
                :style="{ fontFamily: textFont }"
                :class="textSize"
                rows="5"
                class="plain text-creator"
                v-model="text"></textarea>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="col small-12 text-center">
              <button @click="saveText" class="button rounded">
                Confirm
              </button>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';
import Uploader from '../Uploader.vue';
import axios from '../../axios';

export default {
  name: 'EditCustomisationNarrow',
  components: {
    agile: VueAgile,
    Uploader,
  },
  data() {
    return {
      type: 0,
      position: 0,
      size: 0,
      uploader: false,
      uploaded: false,
      designSize: 0,
      notes: '',
      name: '',
      addedToBasket: false,
      artworkLoaded: false,
      chosenArtwork: false,
      textAdded: false,
      textEditor: false,
      image: '',
      warning: '',
      textFont: 'arial',
      textSize: 'small',
      text: '',
      signUpBox: false,
      artworkWarning: '',
      typeWarning: '',
      positionWarning: '',
      sizeWarning: '',
      nameWarning: '',
      largeSizes: false,
    };
  },
  computed: {
    images() {
      return this.$store.state.images;
    },
  },
  watch: {
    images(theImages) {
      if (theImages.length === 1) {
        this.chosenArtwork = 0;
      }
    },
    size(value) {
      if (value !== 4) {
        this.designSize = 0;
      }
      if (value > 0) {
        this.sizeWarning = '';
      }
    },
    type(value) {
      if (value > 0) {
        this.typeWarning = '';
      }
    },
    position(value) {
      if (value > 0) {
        this.positionWarning = '';
      }
      if (value === 3 || value === 7) {
        this.largeSizes = true;
      }
      if (value === 1 || value === 2 || value === 4 || value === 5 || value === 6) {
        this.largeSizes = false;
      }
    },
    image(value) {
      if (value.length > 0) {
        this.artworkWarning = '';
      }
    },
    textAdded(value) {
      if (value === true) {
        this.artworkWarning = '';
      }
    },
    name(value) {
      if (value.length > 0) {
        this.nameWarning = '';
      }
    },
  },
  methods: {
    clearArtwork() {
      this.image = '';
      this.textAdded = false;
    },
    goToProfile(destination) {
      this.$store.commit('setProfileDestination', destination);
      this.$router.push('/profile');
    },
    goBack() {
      this.$router.go('-1');
    },
    chooseArtwork(index) {
      this.chosenArtwork = index;
      this.image = this.$store.state.images[index];
      this.$store.commit('clearImages');
      this.text = '';
      this.textAdded = false;
      this.textFont = '';
      this.textSize = '';
    },
    loadArtwork() {
      const customerId = this.$store.state.customer.id;
      axios.get(`/files/fetchByCustomer/${customerId}.json`)
        .then((response) => {
          this.artworkLoaded = true;
          const images = response.data;
          this.$store.commit('clearImages');
          this.$store.commit('addImages', images);
        });
    },
    saveText() {
      this.textAdded = true;
      this.textEditor = false;
    },
    addNewPosition() {
      this.type = 0;
      this.position = 0;
      this.size = 0;
      this.uploader = false;
      this.uploaded = false;
      this.designSize = 0;
      this.notes = '';
      this.name = '';
      this.addedToBasket = false;
      this.textAdded = false;
      this.text = '';
      this.textFont = 0;
      this.textSize = 0;
    },
    getDesign() {
      const designId = this.$route.params.id;
      axios.get(`/designs/get/${designId}.json`)
        .then((response) => {
          this.type = response.data.type;
          this.position = response.data.position;
          this.size = response.data.size;
          this.designSize = response.data.design_size;
          this.notes = response.data.notes;
          this.name = response.data.name;
          if (response.data.image) {
            this.image = response.data.image;
          }
          this.artworkLoaded = true;
          this.text = response.data.text;
          this.textFont = response.data.text_font;
          this.textSize = response.data.text_size;
          const images = response.data.files;
          this.$store.commit('clearImages');
          this.$store.commit('addImages', images);
          if (this.text.length > 0) {
            this.textAdded = true;
          }
        });
    },
    deleteImage(index) {
      const image = this.images[index];
      const imageId = image.id;
      axios.get(`/files/delete/${imageId}.json`)
        .then((response) => {
          if (response.data === true) {
            this.images.splice(index, 1);
          }
        });
    },
    saveChanges() {
      const designId = this.$route.params.id;
      const postData = {};
      postData.designId = designId;
      postData.basketId = this.$store.state.basketId;
      postData.image = this.image;
      postData.type = this.type;
      postData.position = this.position;
      postData.size = this.size;
      postData.designSize = this.designSize;
      postData.notes = this.notes;
      postData.name = this.name;
      postData.textFont = this.textFont;
      postData.textSize = this.textSize;
      postData.text = this.text;
      if (this.validateDesign(postData) === true) {
        axios.post('/designs/update.json', postData)
          .then(() => {
            this.$router.push('/basket');
          });
      }
    },
    validateDesign(data) {
      let valid = true;
      let scroll = '';
      if (data.name.length === 0) {
        valid = false;
        this.nameWarning = 'Please add a name for your design';
        scroll = 'nameWarning';
      }
      if (data.size === 0) {
        valid = false;
        this.sizeWarning = 'Please select a size.';
        scroll = 'sizeWarning';
      }
      if (data.image.length === 0 && data.textAdded === false) {
        valid = false;
        this.artworkWarning = 'Please add artwork to your design';
        scroll = 'artworkWarning';
      }
      if (data.position === 0) {
        valid = false;
        this.positionWarning = 'Please select a position for your design';
        scroll = 'positionWarning';
      }
      if (data.type === 0) {
        valid = false;
        this.typeWarning = 'Please select either print or embroidery';
        scroll = 'typeWarning';
      }
      const element = document.getElementById(scroll);
      this.$scrollTo(element);
      return valid;
    },
    designSizeChange(direction) {
      this.size = 4;
      if (direction === 1) {
        this.designSize += 1;
      } else {
        this.designSize -= 1;
      }
    },
    closeUploader() {
      // this.uploader = false;
      // this.uploaded = true;
      this.chosenArtwork = (this.images.length - 1);
    },
  },
  mounted() {
    this.getDesign();
  },
};
</script>

<style lang="scss">
  .relative {
    position: relative;
  }
  .image-delete {
    position: absolute;
    top: 5px;
    right: 5px;
    color: red;
    cursor: pointer;
  }
</style>

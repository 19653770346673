<template>
  <div>
    <app-narrow v-if="screenWidth < 865" />
    <app-wide v-if="screenWidth > 864" />
  </div>
</template>

<script>
import narrow from '../components/customisation/EditNarrow.vue';
import wide from '../components/customisation/EditWide.vue';

export default {
  name: 'EditCustomisation',
  computed: {
    screenWidth() {
      return window.screen.width;
    },
  },
  components: {
    appNarrow: narrow,
    appWide: wide,
  },
};
</script>
